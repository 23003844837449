import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@/contexts/auth-context';
import { AdminContext } from '@/contexts/admin-context';
import { LocaleContext } from '@/contexts/locale-context';
import { ThemeContext } from '@/contexts/theme-context';
import { TelemetryContext } from '@/contexts/telemetry-context';
import TextInput from '@/components/text-input';
import ButtonSimple from '@/components/button-simple';
import { Submit } from '@/contexts/validation-context';
import LoadingArc from '@/components/loading-arc';
import ErrorBox from '@/components/error-box';
import { useWidgetModeUtils } from '@/utilities/useWidgetMode';

export default function Login() {
  const {
    loading, isUserLoggedIn, setLoginEmail, setLoginPassword, error: loginError,
    createExternalAuthProvider, externalAuthProvider, loggedInCompanyId,
  } = useContext(AuthContext);
  const { translate } = useContext(LocaleContext);
  const { loginAndSignupPageLogoSize, publicAreaBackgroundColor } = useContext(ThemeContext);
  const { sendEvent } = useContext(TelemetryContext);
  const {
    getLoginWithExternal, getAuth, getCarrierLogo,
    carrierName, getEmailOnlyLogin, getValidationException,
    getWidgetModeEnabled, setIsAdmin,
  } = useContext(AdminContext);
  const router = useRouter();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [externalLoading, setExternalLoading] = useState(false);

  const externalLogin = getLoginWithExternal();
  const validationException = getValidationException();
  const emailOnlyLogin = getEmailOnlyLogin();
  const ssoEnabled = getAuth()?.singleSignOnNoReDirect && externalLogin;

  const { isIFrame, utilsLoaded, getToken } = useWidgetModeUtils();
  const isWidgetMode = utilsLoaded && isIFrame;

  // All portals can login with regular login format, but if emailOnlyLogin is true, then
  // only email is allowed to login (no special characters)
  const loginValidationRules = useMemo(() => {
    // certain portal has historically allowed username to be regular login format but switched to email only login
    // for backward compatibility, we need to allow username to be regular login format for this portal
    // validation exception can be set via admin config
    const isExceptionFromOnlyLogin = username === validationException;
    if (emailOnlyLogin && !isExceptionFromOnlyLogin) {
      return {
        onSubmit: ['email', 'singleByteLogin'],
        onBlur: ['email', 'singleByteLogin'],
      };
    }
    return {
      onSubmit: ['noSpecialCharacters', 'singleByteLogin'],
      onBlur: ['noSpecialCharacters', 'singleByteLogin'],
    };
  }, [emailOnlyLogin, username, validationException]);

  const handleExternalLoginClick = () => {
    setExternalLoading(true);
    externalAuthProvider.login();
  };

  const onSubmitFunction = () => {
    setLoginEmail(username);
    setLoginPassword(password);
  };

  const isLoading = loading || externalLoading;

  const loginButtonText = useMemo(() => {
    if (isLoading) {
      return translate('login.loading');
    }
    return translate('login.login');
  }, [isLoading, translate]);

  useEffect(() => {
    if (externalLogin) {
      // create external auth provider on mount
      // Pass in the auth object from the config
      createExternalAuthProvider(getAuth());
    }
    if (isWidgetMode) {
      // in widget mode get the token from parent frame
      createExternalAuthProvider({ ...getAuth(), authUtils: { getToken } });
    }
  }, [externalLogin, isWidgetMode]);

  useEffect(() => {
    if (isUserLoggedIn && !isWidgetMode) {
      void router.push('/merchants/requests');
      sendEvent('admin_login_user_logged-in');
    }
    if (isUserLoggedIn && isWidgetMode && loggedInCompanyId) {
      sendEvent('merchant_login_user_logged-in');
      setIsAdmin(false);
      void router.push(`/${loggedInCompanyId}/general`);
    }
  }, [isUserLoggedIn, loggedInCompanyId]);

  useEffect(() => {
    // Refresh auth context form values on mount component so user can log in again
    setLoginEmail(username);
    setLoginPassword(password);
  }, []);

  if (getWidgetModeEnabled() && (isIFrame || !utilsLoaded)) return <LoadingArc />;
  if (ssoEnabled && (isUserLoggedIn || isLoading)) return <LoadingArc />;

  return (
    <div className="main">
      <div className="outer">
        {externalLogin ? (
          <div className="content-card">
            {!ssoEnabled ? (
              <>
                <div className="logo-wrapper">
                  {isLoading ? null
                    : <img className="carrier-logo" alt="carrier-logo" src={getCarrierLogo()} />}
                </div>
                <div className="login-heading">
                  {isLoading ? null
                    : translate('login.authenticateAdmin')}
                </div>
                <div className="button-wrapper">
                  {isLoading ? <LoadingArc /> : (
                    <ButtonSimple
                      isLozenge
                      onClick={handleExternalLoginClick}
                    >
                      {loginButtonText}
                    </ButtonSimple>
                  )}
                </div>
              </>
            ) : (
              <>
                <ErrorBox
                  errorMessage={translate(loginError) || translate('login.ssoNotAuthenticated')}
                  showTick={loginError === 'constants.apiErrors.loggedOut'}
                />
              </>
            )}
          </div>
        )
          : (
            <div className="content-card">
              <div className="logo-wrapper">
                {getCarrierLogo() && <img className="carrier-logo" alt="carrier-logo" src={getCarrierLogo()} />}
              </div>
              <div className="login-heading">
                {translate('login.login')}
              </div>
              <div className="form-content">
                <TextInput
                  ariaLabel={translate('login.emailLabel')}
                  inputSelectorId="username-text-input"
                  title={translate('login.emailLabel')}
                  placeholder={translate('login.emailPlaceholder')}
                  onChange={(e) => setUsername(e)}
                  validationRules={{
                    requiredError: translate('login.emailRequiredError'),
                    onSubmit: loginValidationRules.onSubmit,
                    onBlur: loginValidationRules.onBlur,
                  }}
                  required
                  errorLeftAligned
                  defaultErrorMessage={loginError ? ' ' : null}
                />
                <TextInput
                  ariaLabel={translate('login.passwordLabel')}
                  inputSelectorId="password-text-input"
                  title={translate('login.passwordLabel')}
                  placeholder={translate('login.passwordPlaceholder')}
                  onChange={(e) => setPassword(e)}
                  validationRules={{
                    requiredError: translate('login.passwordRequiredError'),
                  }}
                  required
                  isHidden
                  defaultErrorMessage={loginError ? ' ' : null}
                />
              </div>
              {loginError && <p className="login-error">{translate(loginError)}</p>}
              <div className="button-wrapper">
                <Submit
                  inputSelectorId="login-button"
                  buttonLabel={loginButtonText}
                  onSubmitFunction={onSubmitFunction}
                  disabled={loading}
                />
                <div className="link-wrapper">
                  <a href={`/${carrierName}/forgot-password`}>
                    {translate('login.passwordReset')}
                  </a>
                </div>
              </div>
            </div>
          )}
      </div>
      <style jsx>
        {`
          .main {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }

          .outer {
            align-items: center;
            background: ${publicAreaBackgroundColor || "url('/admin-background.jpg') center"};
            background-size: cover;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            width: 100%;
          }

          .content-card {
            background-color: var(--color-white);
            border-radius: var(--border-radius);
            max-width: 100%;
            padding: ${externalLogin && !ssoEnabled ? '65px' : '35px'};
            width: ${externalLogin && ssoEnabled ? '600px' : '450px'};;
          }

          .logo-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: ${externalLogin ? '50px' : '20px'};
          }

          .carrier-logo {
            max-height: ${loginAndSignupPageLogoSize.height}px;
            max-width: ${loginAndSignupPageLogoSize.width}px;
            position: relative;
          }

          .login-heading {
            font-size: var(--font-size-H1);
            font-weight: var(--font-weight-H1);
            margin-bottom: 16px;
            text-align: ${externalLogin ? 'center' : 'left'};
          }

          .button-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: var(--spacing-medium);
          }

          .link-wrapper {
            margin-top: 20px;
            font-size: var(--font-size-body);
            text-decoration: underline;
            color: var(--color-primary-dark);
          }
          
          .login-error {
            color: var(--color-error-red);
            font-size: var(--font-size-error);
            margin: var(--spacing-extra-small) auto;
          }
          
        `}
      </style>
    </div>
  );
}
